body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.com-header{
    position: fixed;
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 8px;
    background-color: #d90429;
        @media screen  and (max-width:600px ){
            padding-rigth: 2px;
    }
    h1,#ic{
        color:#2b2d42;
        cursor: pointer;
        transition: 0.3s;
        @media screen  and (max-width:900px ){
        font-size: 18px;
    }
        @media screen  and (max-width:600px ){
        font-size: 16px;
    }
    }
    #ic:hover{
        box-shadow: 1px 1px 1px;
        background-color: #b6b5b5;
        padding: 7px;
    }
    .com-header-title{
        display: flex;
        align-items: center;
        img{
            padding: 2px;
            margin: 5px;
            cursor: pointer;
        }
    }
    #com-header-title{
        width: 76%;
        @media screen  and (max-width:900px ){
        width: 90%;
    }
    }
    .search-bar{
        margin-left: 20px;
        border-radius: 2px 2px 2px ;
        width: 90%;
        @media screen  and (max-width:600px ){
        margin-left: 1%;
            width: 95%;
    }
        input{
            padding: 8px;
            font-size: 16px;
            width: 100%;
            border: none;
            border-radius:5px;
            background-color: #fff;
            color:#2b2d42;
        }
        input::before{
            color: #3b82f6;
        }

    }

}
.com-container{
    background-color: #f8f9fa;
}
.left-nav{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
    box-shadow: 2px 2px 2px #ebebeb;
    background-color:#fff;
    border-radius: 2px;
    margin: 9px;
    padding: 5px;
    border-right: 1px solid #d1d5db;
    position: fixed;
    margin-top: 12.5vh;
    @media screen and (max-width:1076px){
        display: none;
    }
}
.follow-div{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
        margin: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f8f9fa;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 1px 1px 1px;
        cursor: pointer;
        transition: 0.3s;
    }div:hover{
    background-color: #e6e6e6;}
    @media screen  and (max-width:900px ){
        h2{
            font-size: 18px;
        }
    }
}
.com-button{
    font-size: 15px;
    width: 90%;
    color: #2b2d42;
}
.input-bloc{
    padding: 2px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    label{
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: #2b2d42;
        font-weight: 600;
    }
    input{
        margin: 2px;
        padding: 10px;
        color: #2b2d42;
        font-size: 17px;
        border: none;
        border-radius: 5px;
        background-color: #f1faee;
        width: 90%;
    }
}
.nav-threads{
    margin: 0;
    justify-content: space-between;
}
.threads{
    margin: 0px;
    border: 1px solid #f5f3f3;
    background-color: #fff;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 55%;
    border-radius: 2px 2px 2px;
    box-shadow: 2px 2px 2px #f5f3f3;
    margin-left: 25%;
    padding-top: 3vh;
    @media screen  and (max-width:1076px ){
        margin-left: 2%;
        width: 70%;
    }
    @media screen  and (max-width:900px ){
        width: 100%;
    }
    h1{
        text-align: center;
        font-size: 25px;
    }
    img{
        width: 100%;
        padding-top: 2px;
    }
    h2{
        margin: 10px;
        font-size: 20px;
        padding: 3px;
        span{
            cursor: pointer;
            background-color: #ad112b;
            padding: 4px;
            color: #f9f9f9;
            margin: 3px;
            border-radius: 10px;
            @media screen and (max-width:900px) {
            font-size: 18px;
            padding: 4px;
            @media screen and (max-width:600px) {
            font-size: 16px;
            padding: 3px;
            }
    }
        }
        @media screen and (max-width:900px) {
        font-size: 18px;
            padding: 1px;
    }
        @media screen and (max-width:600px) {
        font-size: 16px;
            padding: 1px;
    }
    }
    article{
        width: 80%;
        margin: 7px;
        padding: 10px;
    }
    .timestamp{
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
    }
    .elements{
        width: 90%;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.3s;
        div{
            width: 8%;
            margin: 2px;
            span{
                display: flex;
                align-items: center;
                img{
                    margin: 3px;
                    padding: 2px;
                }
            }
            @media screen and (max-width: 600px){
                width: 10%;
            }
            @media screen and (max-width: 400px){
                width: 12%;
            }
        }
        margin: 3px;
        border-bottom: 2px solid #ad112b;
    }
}
.right-nav{
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 2px 2px 2px #ebebeb;
    background-color:#fff;
    border-radius: 2px;
    margin: 9px;
    margin-top: 0;
    padding: 5px;
    border-right: 1px solid #d1d5db;
    position: fixed;
    transition: 0.3s;
    #close-btn{
        display: none;
    }
}
.right-nav.active{
        position: fixed;
        z-index: 2000;
        left: 10%;
        right: 50%;
        width: 80%;
        top:35%;
        transition: 0.3s;
        box-shadow: 50px 50px 50px 50px rgba(194, 189, 189, 0.29);
        #close-btn{
            display: block;
        }
    @media screen and (max-width: 700px) {
        top: 20%;
    }
}
.content-thread{
    display: flex;

}