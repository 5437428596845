body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  scroll-behavior: smooth;
}
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
}
.contact {
  display: flex;
  align-items: center;
}

.cont {
  display: flex;
  margin: 8px;
  align-items: center;
}

.cont a {
  text-decoration: none;
  color: #d90429;
  font-size: 25px;
  margin: 5px;
  @media screen and (max-width: 660px) {
    font-size: 18px;
    margin: 1px;
  }
}
.head i {
  color: #284b63;
  font-size: 48px;
  @media screen and (max-width: 660px) {
    font-size: 26px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.head {
  margin: 0px;
  display: grid;
}
.head h1 {
  cursor: pointer;
}
.head h1 span {
  cursor: pointer;
  color: #d90429;
}
nav ul li {
  list-style: none;
  margin-left: 10px;
  display: inline-flex;
  transition: 0.6s;
  justify-content: space-between;
  @media screen and (max-width: 815px) {
    display: block;
    margin-left: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 7px;
  }
}
nav ul li a {
  font-size: 20px;
  text-decoration: none;
  color: #edf2f4;
  padding: 10px;
  @media screen and (max-width: 815px) {
    padding-left: 0;
  }
}
nav ul li:first-child {
  background-color: #284b63;
  color: #284b63;
  @media screen and (max-width: 815px) {
    background: none;
  }
}
nav ul li:first-child a {
  color: #edf2f4;
}
nav ul li:first-child a:hover {
  color: #edf2f4;
  @media screen and (max-width: 815px) {
    background: none;
  }
}
#nav {
  padding-left: 12%;
  margin: 0;
  z-index: 1000;
  transition: 0.8s;
  background-color: #d90429;
  @media screen and (max-width: 815px) {
    padding-left: 0%;
    margin-left: -150%;
    background: none;
    height: 0;
  }
  @media screen and (min-width: 815px) and (max-width: 1060px) {
    padding-left: 1%;
  }
}

nav ul li:hover {
  background-color: #284b63;
  @media screen and (max-width: 815px) {
    background: none;
  }
}
@media screen and (max-width: 660px) {
  h1 {
    font-size: 20px;
  }
}

#nav.active {
  background-color: #d90429;
  margin-left: 0;
  z-index: 2000;
  height: 95vh;
  @media screen and (max-width: 661px) {
    height: 87vh;
  }

  li:first-child {
    background-color: #284b63;
    color: #284b63;
    z-index: 1000;
  }

  nav ul li:first-child a:hover {
    color: #edf2f4;
    background-color: #284b63;
  }
  nav ul li a:hover {
    color: #edf2f4;
    @media screen and (max-width: 815px) {
      background-color: #284b63;
    }
  }
  nav ul li:hover {
    background-color: #284b63;
    background: normal;
    background-color: #284b63;
    text-decoration: underline;
  }
}
#fas {
  cursor: pointer;
}
#close {
  cursor: pointer;
  padding: 8px;
}
@media screen and (min-width: 815px) {
  #fas {
    display: none;
  }
  #close {
    display: none;
  }
}
.main {
  background-image: url("./images/p10.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  z-index: -100;
}
.section {
  background-color: #7575758e;
  justify-content: center;
  z-index: -100;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 82vh;
  padding-top: 0%;
  @media screen and (max-width: 660px) {
    padding: 20px;
    height: 52vh;
  }
}
.section span {
  background-color: #d90429;
}
.p {
  background-color: #edf2f4f5;
  padding: 8px;
}
.text {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  flex-direction: column;
  color: #edf2f4;
  background-color: #2b2d42;
  p {
    padding: 10px;
  }
}
.text span {
  color: #d90429;
}
.products {
  margin: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    flex-basis: 200;
    flex-shrink: 1;
    margin-left: 6%;
  }
}
.product {
  margin: 10px;
  box-shadow: 2px 2px 2px #edf2f4;
  display: flex;
  padding: 8px;
  transition: 0.6s;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.product:hover {
  box-shadow: 1px 1px 1px;
}
.product img {
  width: 80%;
  margin: 10px;
}
button {
  border: none;
  color: #edf2f4;
  background-color: #d90429;
  padding: 8px;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
}
.products a {
  color: #d90429;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2b2d42;
  height: 89vh;
  padding-top: 11vh;
  @media screen and (max-width: 800px) {
    margin-top: 8vh;
  }
  @media screen and (min-width: 801px) and (max-width: 900px) {
    margin-top: 13vh;
  }
}
form label {
  font-size: 18px;
  color: #2b2d42;
  font-weight: 600;
  text-align: center;
  margin: 8px;
}

form input {
  background-color: #edf2f4;

  font-size: 16px;
  padding: 10px;
  margin: 8px;
  border-radius: 8px;
  width: 80%;
  color: #284b63;
  box-shadow: 2px 2px 2px #e6e6e6;
  transition: 3s;
  border: none;

  @media screen and (max-width: 660px) {
    width: 85%;
    padding: 9px;
    margin: 10px;
    font-size: 17px;
  }
}
form input:hover {
  box-shadow: 3px 3px 3px #284b63;
}
.form {
  background-color: #edf2f4;

  box-shadow: 2px 2px 2px #edf2f4;
  padding: 20px;
  display: flex;
  margin: 20px;
  width: 60%;
  border-radius: 10px;
  @media screen and (max-width: 660px) {
    flex-direction: column;
    width: 70%;
    margin: 10px;
    margin-top: 20vh;
  }
  @media screen and (min-width: 661px) and (max-width: 900px) {
    flex-direction: column;
    margin-top: 15vh;
  }
}

.area {
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 60%;
  flex-direction: column;
  @media screen and (max-width: 660px) {
    width: 96%;
    margin: 5px;
  }
  @media screen and (min-width: 661px) and (max-width: 900px) {
    width: 90%;
    margin-left: 0;
  }
}
.first {
  width: 70%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
textarea {
  height: 30vh;
  border-radius: 8px;
  border: none;
  width: 100%;
  text-align: center;
  padding: 2px;
  font-size: 17px;
  @media screen and (max-width: 660px) {
    margin: 0;
    height: 20vh;
  }
}

#submit {
  background-color: #d90429;
  color: #2b2d42;
  transition: 0.6s;
  cursor: pointer;
}
#submit:hover {
  background-color: #edf2f4;
}
#h1 {
  text-align: center;
  text-decoration: underline;
  color: #2b2d42;
}
#h2 {
  background-color: #edf2f4;
  padding: 5px;
  text-align: center;
  text-decoration: underline;
  color: #2b2d42;
}
.class1 {
  margin: 30px;
  display: flex;
  align-items: center;
  background-color: #2b2d42;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  flex-wrap: wrap;
  box-shadow: 2px 2px 2px;
  flex-basis: 200;
  flex-shrink: 1;
  @media screen and (max-width: 700px) {
    margin: 0;
  }
}

.child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  flex-direction: column;
  margin: 10px;
  box-shadow: 1px 1px 1px 1px #284b63;
  hr {
    color: #d90429;
  }
  h1 {
    margin: 4px;
    color: #edf2f4;
    display: flex;
    text-decoration: underline #d90429;
    align-items: center;
    @media screen and (max-width: 700px) {
      width: 100%;
      margin: 0;
    }
    a {
      background-color: #d90429;
      color: #2b2d42;
      padding: 10px;
      border-radius: 30px;
      margin: 4px;
    }
  }
  p {
    padding: 8px;
    line-height: 26px;
    color: #edf2f4;
    font-size: 17px;
  }
  span {
    color: #d90429;
  }
}
.glob {
  padding: 10px;
  background-color: #2b2d42;
  @media screen and (max-width: 900px) {
    margin-top: 25%;
  }
}
.footer {
  margin: 10px;
  background-color: #edf2f4;
  box-shadow: 3px 3px 3px #edf2f4;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  @media screen and (max-width: 900px) {
    margin-top: 30vh;
  }
  p {
    color: #284b63;
    margin: 10px;
  }
}

.product-content {
  display: flex;
  justify-content: space-between;
  flex-basis: 200;
  flex-shrink: 1;
  justify-content: center;
  margin: 10px;
  margin-top: 20px;
  flex-wrap: nowrap;
}

.product-content-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 9px;
  box-shadow: 2px 2px 2px #edf2f4;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
  }
}
.new-line {
  white-space: pre-line;
}
#product-child-img {
  width: 30%;
}
.product-content-child li {
  display: flex;
  animation: 0.5s;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin: 7px;
  img {
    margin: 8px;
    width: 5%;
  }
}

.product-content li:hover {
  cursor: pointer;
  text-decoration: underline #d90429;
}
#product-child h3 {
  text-decoration: underline;
  color: #d90429;
}
.product-content-child a {
  text-decoration: underline;
  color: #d90429;
}
@media screen and (max-width: 700px) {
  #pic {
    width: 40%;
  }
  .product-content h1 {
    font-size: 22px;
  }
  .product-content {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 701px) and (max-width: 900px) {
  #pic {
    width: 65%;
  }
  .product-content {
    flex-wrap: wrap;
  }
  .product-content h1 {
    font-size: 25px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  #pic {
    width: 72%;
  }
  .product-content h1 {
    font-size: 25px;
  }
}
select {
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 1px;
  margin: 20px;
  font-size: 15px;
  color: #edf2f4;
  border-radius: 5px;
  background-color: #284b63;
  option {
    color: #edf2f4;
  }
}
#form {
  @media screen and (max-width: 900px) {
    margin-top: 0vh;
  }
}
.orderDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    color: #d90429;
  }
  h1 {
    color: #2b2d42;
  }
  margin-bottom: 10px;
}
#formPerso {
  background-color: #fff;
  display: flex;
  align-items: start;
  padding: 7px;
  border-radius: 9px;
  justify-content: center;
  box-shadow: 2px 2px 2px #e6e6e6;
  flex-direction: column;
  margin: normal;
  @media screen and (max-width: 1067px) {
    margin-bottom: 3vh;
  }
  @media screen and (min-width: 800px) {
    margin-bottom: 12vh;
    justify-content: center;
  }
  @media screen and (min-width: 900px) {
    margin-top: 5vh;
  }
  img {
    width: 30%;
    margin-left: 24%;
  }
  h1 {
    color: #2b2d42;
    text-decoration: underline;
  }
  input {
    margin: 2px;
    text-align: center;
    font-size: 20px;
    width: 65%;
    @media screen and (max-width: 700px) {
      width: 90%;
    }
    @media screen and (min-width: 701px) and (max-width: 800px) {
      width: 85%;
    }
  }
  label {
    margin: 2px;
    font-weight: bold;
    color: #284b63;
  }
  @media screen and (max-width: 800px) {
    margin-left: 10%;
  }
}
.container {
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  @media screen and (max-width: 1067px) {
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-basis: 200;
  }
}
.perso {
  margin: 10px;
  background-color: #284b63;
}
#courses {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 30px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.courses {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.Only {
  display: flex;
  margin: 10px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

#course:hover {
  background-color: #edf2f4;
}
#course {
  background-color: #fff;
  padding: 7px;
  border-radius: 4px;
  transition: 0.4s;
  box-shadow: 1px 1px 1px #284b63;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 35%;
  }
  @media screen and (max-width: 661px) {
    flex-direction: column;
    margin: 15px;
    justify-content: center;
    width: 100%;
  }
  @media screen and(min-width:62px) and (max-width: 800px) {
    flex-direction: initial;
    margin: 15px;
    justify-content: center;
  }
  @media screen and (min-width: 661px) and (max-width: 800px) {
    margin: 15px;
  }
  a {
    font-size: 20px;
    color: #2b2d42;
    @media screen and (max-width: 661px) {
      flex-direction: column;
      margin: 8px;
      font-size: 20px;
      width: 100%;
    }
    @media screen and (min-width: 660px) and (max-width: 800px) {
      font-size: 27px;
      margin: 5px;
    }
  }
}

.courses a {
  text-decoration: none;
  color: #2b2d42;
}
#perso {
  width: 68%;
  border-right: 2px solid #284b63;
  background-color: #fff;
}
.container h1 {
  color: #2b2d42;
  text-decoration: underline;
}
.container label {
  color: #e6e6e6;
}
.links a {
  margin: 10px;
  text-decoration: underline;
}
.error {
  color: #d90429;
}
#bye {
  margin: 8px;
  display: flex;
  align-items: center;
}
.button {
  display: flex;
  align-items: center;
  color: #2b2d42;
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    flex-basis: 200;
    flex-shrink: 1;
    margin-top: 8vh;
    justify-content: center;
  }
}
.button button {
  font-size: 18px;
}
.sub {
  padding: 20px;
  margin: 3px;
  border: 1px solid;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 1px #e6e6e6;
  border-radius: 10px;
  transition: 0.4s;
}
.sub:hover {
  box-shadow: 3px 3px 3px #b6b5b5;
  transform: rotateY(5deg);
}
.button img {
  width: 30%;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  img {
    margin: 5px;
    cursor: pointer;
  }
}
.animate {
  display: flex;
  align-items: center;
  justify-content: center;
}
#id {
  color: #d90429;
  margin-left: 3px;
}
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10vh;
  img {
    width: 10%;
  }
}
form {
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
}

.choice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#price {
  font-size: 20px;
  padding: 8px;
  transition: 0.4s;
  box-shadow: 1px 1px 1px #b6b5b5;
  width: 80%;
}
#price:hover {
  box-shadow: 2px 2px 2px;
  background-color: #ad112b;
}
iframe {
  width: 100%;
}

.custom-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fond transparent */
}

/* Contenu de l'alerte */
.alert-box {
  background-color: white;
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  width: 340px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 300px;
  }
}

/* Bouton de fermeture */
.close-btn {
  background-color: #d90429;
  color: white;
  border: none;
  font-size: 17px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 16px;
  }
}

.menu-links {
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px #e6e6e6;
}
.close-btn:hover {
  background-color: #d90429;
}

#h22 {
  display: flex;
  align-items: center;
}
.formButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 200;
  flex-shrink: 1;
}

h1,
h2 {
  color: #333;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.8rem;
}

.wrapper {
  max-width: 1200px;
  margin: 20px auto;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.welcome-message {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.welcome-message img {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.header-icons div img {
  margin: 0 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.personal-info-section,
.training-section {
  margin-top: 20px;
}

.personal-info-section form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-info-section form img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.input-label {
  margin-top: 10px;
  font-weight: bold;
  color: #edf2f4;
}

.input-field {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn,
.action-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}
.action-btn {
  background-color: #d90429;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}
.submit-btn:hover,
.action-btn:hover {
  background-color: #0056b3;
}
.action-btn:hover {
  background-color: #284b63;
  color: #e6e6e6;
}
.button-section {
  margin: 20px 0;
}
#para {
  color: #e6e6e6;
}
.action-btn {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.action-btn img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.training-courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.course-item-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.course-item {
  flex-basis: 48%;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-item img {
  margin-bottom: 10px;
}

.course-item a {
  font-size: 1.2rem;
  color: #284b63;
  text-decoration: none;
}

.course-item a:hover {
  text-decoration: underline;
}

.links-section {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.links-section a {
  color: #2b2d42;
  text-decoration: none;
  font-size: 1rem;
}

.links-section a:hover {
  text-decoration: underline;
}

/* Responsivité */
@media screen and (max-width: 768px) {
  .header-icons {
    flex-direction: column;
    align-items: flex-start;
  }

  .welcome-message {
    font-size: 1.3rem;
  }

  .personal-info-section,
  .training-section {
    width: 100%;
  }

  .training-courses {
    flex-direction: column;
  }

  .course-item-group {
    flex-direction: column;
    width: 100%;
  }

  .course-item {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .links-section {
    flex-direction: column;
  }

  .links-section a {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .welcome-message img {
    width: 30px;
    height: 30px;
  }

  .input-field {
    max-width: 100%;
  }

  .action-btn {
    font-size: 0.9rem;
  }

  .action-btn img {
    width: 25px;
    height: 25px;
  }

}
#personal-info-form {
  background-color: #edf2f4;
  height: 100%;
  a {
    color: #2b2d42;
  }
}
#cancel-btn,
#submit-btn {
  color: #e6e6e6;
  background-color: #2b2d42;
  cursor: pointer;
}
.links-section a {
  color: #333;
}
#course1,
#course2,
#course3,
#course4 {
  color: #284b63;
}
#tareaText {
  border: 1px solid;
}
/* Style de base pour le bouton d'upload */
.file-input {
  padding: 10px;
  border: 2px dashed #007bff; /* Bordure en pointillés */
  border-radius: 8px;
  background-color: #f9f9f9; /* Couleur de fond */
  cursor: pointer;
  font-family: "Arial", sans-serif;
  color: #333;
  width: 100%;
  max-width: 400px;
  transition: background-color 0.3s ease;
}

.file-input:hover {
  background-color: #e0f0ff;
  border-color: #0056b3;
}

.file-input:active {
  background-color: #cce0ff;
  border-color: #003d80;
}

#fileUploader::file-selector-button {
  margin-right: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#fileUploader::file-selector-button:hover {
  background-color: #0056b3;
}
#ID {
  background-image: url("./images/p11.jpg");
}

.coming {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
}

@media screen and (max-width: 600px) {
  #logo {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .form {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .glob {
    margin-top: 6vh;
  }
}

#h2 {
  background-color: floralwhite;
  @media screen and (max-width: 600px) {
    margin-top: 28vh;
    margin-bottom: 0;
  }
  @media screen and (min-width: 601px) and (max-width: 900px) {
    margin-top: 30vh;
  }
}
.footer {
  @media screen and (max-width: 600px) {
    margin-top: 0vh;
  }
  @media screen and (min-width: 601px) and (max-width: 1067px) {
    margin-top: 2vh;
  }
}
/* Styles généraux */
.footerr {
  display: flex;
  align-items: center;
  background-color: #edf2f4;
  justify-content: center;
  margin: 10px;
  @media screen and (max-width: 900px) {
    margin: 10px;
    margin-top: 35vh;
  }
}

.main-bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1600px;
  margin: 0 auto;
}

.contentOne,
.contentTwo {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 100%;
}

/* Vidéo */
video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Titre */
.title {
  text-align: center;
  margin-top: 10px;
}

.title h1 {
  font-size: 2em;
  color: #ad112b;
}

.title p {
  font-size: 1.2em;
  color: #666;
}

/* Contrôles */
.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.controls button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: #0056b3;
}

.controls img {
  width: 30px; /* Ajustez selon vos besoins */
  height: auto;
}

/* Section Commentaires */
.comments {
  margin-top: 20px;
}

.comments label {
  font-weight: bold;
}

.comments textarea {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
  margin-top: 5px;
  padding: 5px;
}

.comments input[type="submit"] {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
}

.comments input[type="submit"]:hover {
  background-color: #218838;
}

/* Description */
.contentTwo {
  padding: 20px;
}

.contentTwo p {
  font-size: 1.5em;
  font-weight: bold;
}

.contentTwo article {
  margin-top: 10px;
  font-size: 1em;
  color: #444;
}

/* Responsive Design */
@media (min-width: 1000px) {
  .main-bloc {
    flex-direction: row;
  }

  .contentOne,
  .contentTwo {
    flex: 1;
    margin: 10px;
  }

  .contentOne {
    margin-right: 20px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.flexbox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 98%;
  max-width: 1500px;
  margin: 0 auto;
}

.flexbox > div {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.flexbox > div:hover {
  transform: scale(1);
}

.flexbox {
  h2 {
    color: #555;
    font-size: 1.2em;
    margin-bottom: 15px;
  }

  label {
    font-weight: bold;
    color: #333;
  }

  input[type="text"],
  textarea,
  .file-input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea {
    resize: vertical;
    height: 80px;
  }
}

.file-input {
  border: none;
}

.flexbox {
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #0056b3;
  }
}
/* Responsive styling */
@media (max-width: 768px) {
  .flexbox {
    #h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.1em;
    }

    button {
      width: 100%;
    }
  }
}

.ValidateOrder {
  input {
    padding: 10px;
    border-radius: 7px;
    margin: 5px;
    width: 100%;
  }
  label {
    font-size: 20px;
    margin: 5px;
  }
}
.menu-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    text-decoration: underline #61dafb;
    color: #2b2d42;
    margin: 2px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.language-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.language-card {
  position: relative;
  width: 160px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.language-card img {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.language-card .icon-plus {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 18px;
  color: #000000b3;
}

.language-card:hover {
  transform: scale(1.1);
}

/* Background colors for each language */
.html {
  background-color: #e34c26;
}
.css {
  background-color: #264de4;
}
.js {
  background-color: #f0db4f;
  color: #323330;
}
.nodejs {
  background-color: #68a063;
}
.python {
  background-color: #306998;
}
.react {
  background-color: #61dafb;
  color: #282c34;
}
.mysql {
  background-color: #f29111;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .language-card {
    width: 120px;
    height: 80px;
  }
  .language-card img {
    width: 30px;
    height: 30px;
  }
  .language-card .icon-plus {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .language-card {
    width: 85px;
    height: 70px;
  }
  .language-card img {
    width: 25px;
    height: 25px;
  }
  .language-card .icon-plus {
    font-size: 14px;
  }
}

.length {
  box-shadow: 3px 3px 3px #e08888;
  padding: 12px;
  p {
    font-size: 25px;
  }
  span {
    background-color: #ad112b;
    padding: 5px;
    color: #f9f9f9;
    border-radius: 20px;
  }
}
.product a {
  font-size: 20px;
  color: #2b2d42;
  text-decoration: underline 1px;
}
.product a:first-child {
  font-size: 20px;
  color: #2b2d42;
  text-decoration: none;
}
.butt {
  width: 100%;

  background-color: #f4f4f4;
  box-shadow: 2px 2px 2px #f9f9f9;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  flex-direction: column;
}
.footerr img {
  margin: 5px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 10px;
  box-shadow: 2px 2px 2px #e08888;
  border-radius: 10px;
  background-color: #2b2d42;
  h2 {
    color: #edf2f4;
  }
  span {
    background-color: #ad112b;
    padding: 5px;
    color: #f9f9f9;
    border-radius: 20px;
  }
}
.users {
  margin: 8px;
  padding: 10px;
  border: 2px solid #ad112b;
}

.chat-btn {
  position: fixed;
  z-index: 1000;
  border-radius: 5px;
  padding: 5px;
  top: 550px;
  right: 10px;
  background-color: #edf2f4;
  border-radius: 20px;
  @media screen and (max-width: 1067px) {
    top: 517px;
  }
  @media screen and (max-width: 800px) {
    top: 560px;
  }
}
.chat-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fond transparent */
}
.menu-links textarea {
  resize: none;
  margin: 10px;
  width: 95%;
}
.menu-links input {
  padding: 5px;
  border-radius: 3px;
  color: #edf2f4;
  cursor: pointer;
  background-color: #284b63;
}
.menu-links input:hover {
  background-color: #2b2d42;
}
.chil p {
  cursor: pointer;
  text-decoration: underline;
}
/* Contenu de l'alerte */
.chat-box {
  background-color: white;
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  overflow-y: scroll;
  height: 65%;
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 380px;
  }
}

.chat {
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: 2px 2px 2px #edf2f4;
  p {
    background-color: #fff;
    padding: 7px;
    margin: 12px;
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: center;
  }
  a {
    color: #ad112b;
    font-weight: 600;
  }
}
.chat span {
  background-color: #ad112b;
  color: #edf2f4;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
}
.menu-links {
  button {
    margin: 2px;
  }
}
.chil {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profil {
  display: flex;
  margin: 7px;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 15px;
    font-weight: 400;
  }
}
#comment {
  box-shadow: 2px 2px 2px #f4f4f4;
  border: 1px solid #e0f0ff;
  text-align: center;
  padding: 10px;
  margin: 8px;
  font-size: 15px;
}

#details {
  transition: 0.3s;
  font-size: 15px;
  box-shadow: 1px 1px 1px #2b2d42;
}
#details:hover {
  background-color: #a75454;
  box-shadow: 1px 1px 1px #284b63;
}
#detail {
  transition: 0.3s;
  font-size: 15px;
  box-shadow: 1px 1px 1px #2b2d42;
}
#detail:hover {
  background-color: #a75454;
  box-shadow: 1px 1px 1px #284b63;
}
/* Global styles */
.admin {
  background-color: #f5f5f5;
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  }

  h1,
  h2,
  h3 {
    color: #444;
  }

  h3#h1 {
    text-align: center;
    font-size: 1.8rem;
    color: #282c34;
    padding-top: 20px;
  }

  /* Main dashboard container */
  .flexbox {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: space-around;
  }

  /* User stats */
  .user {
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 20px auto;
    width: 90%;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .users {
    padding: 20px;
    font-size: 1.2rem;
    color: #4b5563;
    border: none;
  }

  .users h2 {
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .users span {
    margin: 1px;
    font-weight: bold;
    color: #f4f4f4;
  }

  /* Form and content containers */
  .ValidateOrder,
  .file-input-container,
  textarea,
  input[type="text"],
  select {
    width: 100%;
    max-width: 300px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 0.95rem;
  }

  textarea {
    min-height: 80px;
    resize: vertical;
  }

  button {
    background-color: #f29111;
    color: white;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #f0db4f;
  }

  /* Video upload form */
  .file-input {
    border: none;
    padding: 0;
  }

  .progress-bar-container {
    background-color: #d1d5db;
    height: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .progress-bar {
    height: 100%;
    background-color: #3b82f6;
    color: white;
    text-align: center;
    transition: width 0.4s ease;
  }

  /* Message and order section */
  .flexbox > div {
    width: 30%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .flexbox > div h2 {
    color: #282c34;
  }

  .flexbox label {
    font-weight: 600;
    margin-top: 10px;
    color: #4b5563;
  }

  textarea,
  input[type="text"] {
    background-color: #f9fafb;
  }

  /* Back to top button */
  .flexbox a {
    text-decoration: none;
    color: #282c34;
  }

  .flexbox a:hover {
    color: #2563eb;
  }
  .message-card,
  .order-card {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    margin-top: 10px;
  }
}
.users span {
  background-color: #fbfdff;
  h2 {
    color: #2b2d42;
  }
}
#inscription-form {
  width: 100%;
  height: 100vh;
  padding-top: 1vh;
  background-color: #f9f9f9;
  label {
    font-size: 20px;
  }
  @media screen and (max-width: 900px) {
    margin: 0;
    padding: 0;
    height: 100%;
  }
}
#form-contact {
  height: 100vh;
  padding-top: 1vh;
  background-color: #f9f9f9;
  label {
    font-size: 18px;
    margin: 2px;
  }
}
.area {
  padding: 16px;
}
.inscription-form {
  margin-top: 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  height: 100%;
  width: 82%;
  box-shadow: 3px 3px 3px #ebebeb;
  padding: 20px;
  background-color: #fff;
  input {
    width: 100%;
    padding: 15px;
    margin: 1px;
    font-size: 20px;
    border-radius: 1px;
    border: 0.5px solid whitesmoke;
    background-color: #fff;
  }
  a {
    margin-top: 2vh;
    color: #282c34;
  }
  #submit {
    color: white;
    background-color: #2b2d42;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 99%;
    margin-top: 3vh;
  }
  textarea {
    border: 1px solid;
  }
  #h1 {
    margin-top: 1vh;
  }
}
.inscription-form-config {
  margin-top: 1vh;
  flex-shrink: 1;
  height: 85%;
  width: 87%;
  box-shadow: 3px 3px 3px #ebebeb;
  padding: 20px;
  background-color: #fff;
  input {
    width: 100%;
    padding: 20px;
    margin: 8px;
    font-size: 18px;
    border-radius: 1px;
    border: 0.5px solid whitesmoke;
    background-color: #fff;
  }
  a {
    margin-top: 2vh;
    color: #282c34;
  }
  #submit {
    color: white;
    background-color: #2b2d42;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.first {
  padding: 0;
  margin: 0;
}
#form-contact-div {
  margin-top: 1vh;
  flex-shrink: 1;
  height: 85%;
  width: 35%;
  box-shadow: 3px 3px 3px #ebebeb;
  padding: 20px;
  background-color: #fff;
  input {
    width: 100%;
    padding: 20px;
    margin: 8px;
    font-size: 18px;
    border-radius: 1px;
    border: 0.5px solid whitesmoke;
    background-color: #fff;
  }
  a {
    margin-top: 2vh;
    color: #282c34;
  }
  #submit {
    color: white;
    background-color: #2b2d42;
  }
}
@media screen and (max-width: 1074px) {
  #form-contact-div {
    width: 47%;
    margin-top: 0;
  }
  #form-contact {
    margin-top: 0;
  }
}
@media screen and (max-width: 800px) {
  #form-contact-div {
    width: 97%;
    margin-top: 0;
  }
}
#h2 {
  background: none;
  margin: 6px;
  @media screen and (max-width: 900px) {
    margin-top: 1vh;
  }
}
.glob {
  background-color: #fff;
  p,
  h1 {
    color: #2b2d42;
  }
  display: flex;
  flex-shrink: 1;
  flex-basis: 200;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

#inscription-form {
  background-color: #fff;
}
.product {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products {
  display: flex;
  align-items: center;
  flex-shrink: 1;

  flex-basis: 200;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    .product {
      width: 45%;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    .product {
      width: 90%;
    }
  }
  @media screen and (max-width: 500px) {
    .product {
      width: 95%;
    }
  }
}
.class1 {
  width: 100%;
  background-color: #fff;
  border: none;
  box-shadow: 3px 3px 3px #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-basis: 200;
  flex-wrap: wrap;
}
.child {
  border: none;
  box-shadow: none;
  box-shadow: 3px 3px 3px #f9f9f9;
}
.content-int {
  width: 96%;
  margin-left: 2%;
}
.course-item {
  transition: 0.3s;
  cursor: pointer;
  a {
    font-size: 25px;
    font-weight: 600;
  }
  background-color: #fff;
  img {
    width: 50%;
  }
  #last-child {
    width: 67%;
  }
}
.course-item:hover {
  transform: translateY(-4%);
}
.product:hover {
  transform: translateY(-4%);
}
#h2 {
  text-decoration: none;
}
.glob,
.child {
  align-items: normal;
}
.class1 {
  margin-left: 5%;
  width: 90%;
  box-shadow: none;
  border-left: 2px solid #ebebeb;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-left: 1%;
  }
  p {
    span {
      background-color: #ad112b;
      color: #edf2f4;
      padding: 9px;
      font-size: 18px;
      margin: 20px;
      margin-left: 0;
      margin-top: 0;
      border-radius: 20px;
    }
    width: 50%;
    padding: 10px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
}
 .glob .class1 .left-part {
  margin: 10px;
  padding: 20px;
  margin: normal;
  margin-left: 52% !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-left: 2px solid #ebebeb;
  box-shadow: 3px 3px 3px #ececec;
}
@media screen and (max-width: 700px) {
  .glob .class1  .left-part{
    margin-left: 0  !important ; 
    h1 {
      font-size: 18px;
    }
  }
}
.glob .class1 .rigth-part {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 3px 3px 3px #ececec;
  margin: 10px;
  padding: 20px;
  border-right: 2px solid #ebebeb;
  margin: normal;
  @media screen and (max-width: 700px) {
    margin-left: 0;
    h1 {
      font-size: 18px;
    }
  }
}
.sml {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 20px;
    justify-content: center;
    flex-direction: column;
    color: #282c34;
  }
  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
}
.social-media-link {
  h1 {
    text-align: center;
    font-size: 18px;
  }
}
.temoins {
  width: 68%;
  textarea {
    resize: none;
    border: none;
    background-color: #f3f3f3;
    font-size: 20px;
    padding: 40px;
    @media screen and (max-width:700px) {
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  }
  @media screen and (max-width:700px) {
    width: 90%;
  }
}
.tem-bloc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temoins {
  p {
    font-size: 25px;
    margin: 6px;
  }
  padding-top: 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.temoins-child {
  display: flex;
  align-items: center;
  img {
    margin: 5px;
  }
}
/* Personnalisation des flèches */
.temoins{
  .swiper-button-next,
.swiper-button-prev {
  padding-top: 15vh;
}
}

.swiper-slide {
  display: none;
}
.course-item-footer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  button{
    background-color: #e08888;
    color: #2b2d42;
    transition: 0.4s;
    padding: 10px;
    width: 60%;
    font-size: 18px;
    box-shadow: 3px 3px 3px #ececec;
    border-radius: 5px;
  }
  button:first-child{
    background-color: #ececec;
  }
  button:hover{
    background-color: #ececec;
  }
  button:first-child:hover{
    background-color: #e08888;
  }
}
.contentTwo button{
  background-color: #ececec;
  color: #2b2d42;
  transition: 0.4s;
  padding: 10px;
  width: 60%;
  font-size: 18px;
  box-shadow: 3px 3px 3px #ececec;
  border-radius: 5px;  
}
.contentTwo button:hover{
  background-color: #e08888;
  color: #2b2d42;

}
.order-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .part-1{
    margin:20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  };
  .part-2{
    display: flex;
    align-items: center;
    @media screen and (max-width:800px) {
      flex-direction: column;
      flex-shrink: 1;
    }
  }
  .option-item{
    margin: 10px;
    @media screen and (max-width:800px) {
      img{
        margin: 15px;
      }
    }
  }
  p{
    font-size: 20px;
  }
  button{
    background-color: #ececec;
    color: #2b2d42;
    transition: 0.4s;
    padding: 10px;
    width: 90%;
    font-size: 18px;
    box-shadow: 3px 3px 3px #ececec;
    border-radius: 5px;
  }
  button:hover{
    background-color: #e08888;
  }
  select{
    padding: 10px;
    @media screen and (max-width:600px) {
      width: 90%;
    }
  }
}

.success-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .success-child{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 3px 3px 3px #ececec;
    background-color: #28a745;
    h3{
      color: floralwhite;
    }
    button{
      background-color: #fff;
      color: green;
    }
  }
}
.vid-option{
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    margin: 8px;
    transition: 0.4s;
    cursor: pointer;
    background-color: #ececec;
    padding: 6px;
    border-radius: 10px;
  }img:hover{
    padding: 8px;
    box-shadow: 4px 4px 4px #ececec;
  }
}
.liked-bloc{
  align-items: center;
}
#no-liked:hover{
  background-color: #c93131;
}
