/*Feeback option  */
.feed-back-div button{
    background-color: #2b2d42;
    color: #fff;
    transition: 0.4s;
    padding: 10px;
    width: 96%;
    font-size: 18px;
    box-shadow: 3px 3px 3px #ececec;
    border-radius:20px;
  }
.feed-back-div button:hover{
    background-color: #2c4652be;
}
.feed-back-div{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #635d5d75;
    textarea{
        resize: none;
        background-color: #ececec;
        border-radius: 10px;
        height: 70%;
        padding: 10px;
        text-align: center;
    }
    p{
        padding: 10px;
        font-size: 20px;
        font-weight: 600;
    }
    .feed-back-div-child{
        padding: 10px;
        border-radius: 15px;
        position: fixed;
        width: 27%;
        top: 20%;
        height: 55%;
        background-color: #fff;
        @media screen and (max-width:1100px) {
            width: 36%;
        }
        @media screen and (max-width:1000px) {
            width: 42%;
        }
        @media screen and (max-width:800px) {
            width: 50%;
        }
        @media screen and (max-width:600px) {
            width: 60%;
        }
        @media screen and (max-width:650px) {
            width: 70%;
        }
        @media screen and (max-width:400px) {
            width: 87%;
        }
    }

}